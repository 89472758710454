import React from 'react';
import './App.css';
import { ThemeProvider } from './ThemeProvider';
import { GeneralProvider } from './GeneralProvider'; // No need for useContext here
import { LanguageProvider } from './LanguageProvider';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import NotFound from './pages/NotFound/NotFound';
import ProtectedRoute from './ProtectedRoute'; // New component for protected route logic
import { ToasterProvider } from './ToasterProvider';
import Layout from './Layout/Layout';
import CountdownToJan2025 from './components/CountDown/CountDownToJan2025';
import LandingPage from './pages/LandingPage/LandingPage';
import AuthPage from './pages/AuthPage/AuthPage';

function App() {
  return (
    <LanguageProvider>
      <ToasterProvider>
        <GeneralProvider>
          <ThemeProvider>
            <CountdownToJan2025 />
            <Router>
              <Routes>
                <Route path="*" element={<NotFound />} />
                 <Route path="/test-landing" element={<LandingPage />} />
                <Route path="/test-auth" element={<AuthPage />} /> 
                <Route path="/" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/home" element={<ProtectedRoute element={<Layout />} />} />
              </Routes>
            </Router>
          </ThemeProvider>
        </GeneralProvider>
      </ToasterProvider>
    </LanguageProvider>
  );
}

export default App;
