import React, { useState, useEffect, useContext } from "react";
import Timer from "./Views/Timer";
import DateRangeSelector from "./Views/DateRangeSelector";
import TimerList from "./Views/TimerList";
import "./Kronos.css";
import { GeneralContext } from "../../GeneralProvider";
import { useLanguage } from "../../LanguageProvider";
import { useToaster } from "../../ToasterProvider";

export default function Kronos() {
  const { getTranslation } = useLanguage();
  const { toastError, toastSuccess } = useToaster();
  const [currentLog, setCurrentLog] = useState(null);
  const [kronosData, setKronosData] = useState(null);
  const [timeLog, setTimeLog] = useState([]); // TimerList'e verilecek varsayılan veri
  const [groups, setGroups] = useState([]); // Gruplu veriler
  const [ungroup, setUngroup] = useState([]); // Gruplandırılmamış veriler

  const {
    handleKronosCreateAndStart,
    handleKronosStop,
    handleKronosGetByToken,
    handleKronosDelete,
    handleGetCurrentKronos,
    handleManualKronosCreate,
    selectedProjectID,
    handleKronosDiscard,
    userId,
    setDateRangeAndFetch,
    dateRange,
  } = useContext(GeneralContext);

  // Kronos verilerini servisten çekme işlevi
  function processKronosData(response, currentKronosId = null) {
    const processedUngroup = response.result.ungroup
      .filter((item) => item.kronosId !== currentKronosId)
      .map((item) => ({
        ...item,
        timeLogs: item.timeLogs.map((log) => ({
          ...log,
          startDateTime: log.startDateTime || null,
          endDateTime: log.endDateTime || null,
          duration: log.endDateTime
            ? Math.floor(
                parseInt(log.endDateTime, 10) - parseInt(log.startDateTime, 10)
              )
            : 0,
        })),
      }));

    const processedGroups = response.result.groups.map((group) => ({
      ...group,
      kronoses: group.kronoses.map((kronos) => ({
        ...kronos,
        timeLogs: kronos.timeLogs.map((log) => ({
          ...log,
          startDateTime: log.startDateTime || null,
          endDateTime: log.endDateTime || null,
          duration: log.endDateTime
            ? Math.floor(
                parseInt(log.endDateTime, 10) - parseInt(log.startDateTime, 10)
              )
            : 0,
        })),
      })),
    }));

    return { processedUngroup, processedGroups };
  }

  async function refreshKronosData(newRange) {
    try {
      const currentKronos = await handleGetCurrentKronos();
      const currentKronosId = currentKronos?.status
        ? currentKronos.result.kronosId
        : null;

      const response = await handleKronosGetByToken(
        newRange ? newRange : dateRange
      );

      if (response.status) {
        const { processedUngroup, processedGroups } = processKronosData(
          response,
          currentKronosId
        );

        setUngroup(processedUngroup);
        setGroups(processedGroups);
        setTimeLog(processedUngroup);
      }
    } catch (error) {
      console.error("Kronos verisi yenilenemedi:", error);
    }
  }

  useEffect(() => {
    async function fetchInitialKronosData() {
      try {
        let currentKronosId = null;

        const currentKronos = await handleGetCurrentKronos();
        if (currentKronos?.status) {
          if (
            currentKronos.result &&
            currentKronos.result.projectId === selectedProjectID
          ) {
            const { kronosTitle, logId, kronosId, startDateTime } =
              currentKronos.result;

            currentKronosId = kronosId;

            // Başlangıç zamanını hesapla
            const now = Math.floor(Date.now() / 1000);
            const elapsedTime = now - startDateTime;

            // Timer'ı güncelle ve başlat
            setCurrentLog({
              title: kronosTitle,
              logId: logId,
              kronosId: kronosId,
              start: new Date(startDateTime * 1000),
              duration: elapsedTime,
            });

            setKronosData({
              kronosId: kronosId,
              logId: logId,
              timestamp: now,
              status: true,
            });
          }
        }

        // Kronos verilerini getir
        const response = await handleKronosGetByToken(dateRange);
        if (response.status) {
          const { processedUngroup, processedGroups } = processKronosData(
            response,
            currentKronosId
          );

          setUngroup(processedUngroup);
          setGroups(processedGroups);
          setTimeLog(processedUngroup);
        }
      } catch (error) {
        console.error("Kronos verisi çekilemedi:", error);
      }
    }

    fetchInitialKronosData();
  }, [selectedProjectID, dateRange]);

  const handleAddLog = (log) => {
    setTimeLog((prevLogs) => [...prevLogs, log]);
  };

  const handleDelete = async (logId) => {
    const index = timeLog.findIndex((log) =>
      log.timeLogs.some((timeLog) => timeLog.logId === logId)
    );
    if (index === -1) return;

    const log = timeLog[index];
    const kronosId = log.kronosId;
    const updatedTimeLogs = log.timeLogs.filter(
      (timeLog) => timeLog.logId !== logId
    );

    try {
      const response = await handleKronosDelete([`${kronosId}`]);
      if (response.status) {
        console.log("Silme işlemi başarılı:", response);

        if (updatedTimeLogs.length === 0) {
          setTimeLog((prevLogs) => [
            ...prevLogs.slice(0, index),
            ...prevLogs.slice(index + 1),
          ]);
        } else {
          const updatedLog = {
            ...log,
            timeLogs: updatedTimeLogs,
          };
          setTimeLog((prevLogs) => [
            ...prevLogs.slice(0, index),
            updatedLog,
            ...prevLogs.slice(index + 1),
          ]);
        }

        await refreshKronosData();
      } else {
        console.error("Silme işlemi başarısız:", response);
      }
    } catch (error) {
      console.error("Silme işlemi sırasında hata oluştu:", error);
    }
  };

  const handleUpdateLog = (index, field, newValue) => {
    const updatedLog = [...timeLog];
    const log = updatedLog[index];

    if (field === "start") {
      log.start = new Date(newValue);
      if (log.end) {
        log.duration = calculateDuration(log.start, log.end);
      }
    } else if (field === "end") {
      log.end = new Date(newValue);
      if (log.start) {
        log.duration = calculateDuration(log.start, log.end);
      }
    } else if (field === "duration") {
      const newDuration = parseInt(newValue, 10);
      log.duration = newDuration;
    } else {
      log[field] = newValue;
    }

    setTimeLog(updatedLog);
  };

  const calculateDuration = (start, end) => {
    const diffInSeconds = Math.floor((end - start) / 1000);
    return diffInSeconds;
  };

  const handlePlayLog = (log) => {
    setCurrentLog(log);
  };

  const handleStartKronos = async (log) => {
    console.log("Kronos'tan provider'a gönderilen log: ", log);
    try {
      const result = await handleKronosCreateAndStart(log);
      console.log("Kronos --> Proivder'dan dönen data: ", result);
      if (result.status) {
        setKronosData({
          kronosId: result.result.kronosId,
          logId: result.result.logId,
          kronosId: result.result.kronosId,
          timestamp: result.timestamp,
          status: result.status,
        });
        return result;
      } else {
        console.error("Failed to start Kronos.");
      }
    } catch (error) {
      console.error("Error in KronosCreateAndStart:", error);
    }
  };

  const handleStopKronos = async (logId) => {
    console.log("Kronos Stop --> Gönderilen Log ID: ", logId);
    try {
      const result = await handleKronosStop(logId);
      console.log("Kronos Stop --> Dönen Veri: ", result);
      if (result.status) {
        await refreshKronosData();
      }
      return result;
    } catch (error) {
      console.error("Error in Kronos Stop:", error);
      return { status: false };
    }
  };

  const handleSavePreviousAndStart = async (
    currentKronosData,
    currentTitle
  ) => {
    if (!currentKronosData || !currentKronosData.logId) return;

    try {
      const stopResult = await handleKronosStop(currentKronosData.logId);
      console.log("Kronos Stop Result: ", stopResult);

      if (stopResult?.status) {
        toastSuccess(getTranslation("successMessage"));

        // Yeni Kronos başlat
        const newTimerResult = await handleKronosCreateAndStart({
          title: currentTitle || "Unnamed Task",
          members: [{ id: userId, permission: "write" }],
        });

        if (newTimerResult?.status) {
          console.log("Yeni Kronos Başlatıldı: ", newTimerResult);
          setCurrentLog({
            title: currentTitle || "Unnamed Task",
            logId: newTimerResult.result.logId,
            kronosId: newTimerResult.result.kronosId,
            start: new Date(),
            duration: 0,
          });
        } else {
          toastError(getTranslation("somethingWentWrong"));
        }
      } else {
        toastError(getTranslation("somethingWentWrong"));
      }
    } catch (error) {
      toastError(getTranslation("somethingWentWrong"));
      console.error("Error in saving previous Kronos: ", error);
    }
  };

  const handleCancelPreviousAndStart = async (
    currentKronosData,
    currentTitle
  ) => {
    if (!currentKronosData || !currentKronosData.kronosId) return;

    try {
      const discardResult = await handleKronosDiscard(
        currentKronosData.kronosId
      );
      console.log("Kronos Discard Result: ", discardResult);

      if (discardResult?.status) {
        toastSuccess(getTranslation("successMessage"));

        // Yeni Kronos başlat
        const newTimerResult = await handleKronosCreateAndStart({
          title: currentTitle || "Unnamed Task",
          members: [{ id: userId, permission: "write" }],
        });

        if (newTimerResult?.status) {
          console.log("Yeni Kronos Başlatıldı: ", newTimerResult);
          setCurrentLog({
            title: currentTitle || "Unnamed Task",
            logId: newTimerResult.result.logId,
            kronosId: newTimerResult.result.kronosId,
            start: new Date(),
            duration: 0,
          });
        } else {
          toastError(getTranslation("somethingWentWrong"));
        }
      } else {
        toastError(getTranslation("somethingWentWrong"));
      }
    } catch (error) {
      toastError(getTranslation("somethingWentWrong"));
      console.error("Error in discarding previous Kronos: ", error);
    }
  };

  const handleDiscardAndResetTimer = async () => {
    console.log("kronos'a geldi.", kronosData);
    if (!kronosData || !kronosData.kronosId) return;
    try {
      const discardResult = await handleKronosDiscard(kronosData.kronosId);
      console.log("discardResult: ", discardResult);
      if (discardResult?.status) {
        setKronosData(null);
        setCurrentLog(null);

        await refreshKronosData();
        return true;
      }
    } catch (error) {
      console.error("Error in discarding Kronos: ", error);
    }
  };

  const handleCreateManualKronos = async (title, startDate, endDate) => {
    const members = [{ id: userId, permission: "write" }];
    try {
      const createManualResult = await handleManualKronosCreate(
        title,
        members,
        startDate,
        endDate
      );

      console.log("kronos createManualResult: ", createManualResult);

      return createManualResult?.status;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (kronosData) {
      console.log("Kronos --> Güncellenen kronosData:", kronosData);
    }
  }, [kronosData]);

  return (
    <div className="main-page">
      <Timer
        onAddLog={handleAddLog}
        timeLog={timeLog}
        setTimeLog={setTimeLog}
        currentLog={currentLog}
        createAndStart={handleStartKronos}
        stop={handleStopKronos}
        refreshKronosData={refreshKronosData}
        handleGetCurrentKronos={handleGetCurrentKronos}
        handleCreateManualKronos={handleCreateManualKronos}
        handleDiscardAndResetTimer={handleDiscardAndResetTimer}
        handleSavePreviousAndStart={handleSavePreviousAndStart}
        handleCancelPreviousAndStart={handleCancelPreviousAndStart}
      />
      <DateRangeSelector onFetchData={refreshKronosData} />

      <TimerList
        timeLog={timeLog}
        handleDelete={handleDelete}
        handleUpdateLog={handleUpdateLog}
        onPlay={handlePlayLog}
        setTimeLog={setTimeLog}
        currentKronos={currentLog}
      />
    </div>
  );
}
