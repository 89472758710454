import React, { useState, useEffect } from "react";
import Modal from "../../../components/Modal/Modal";
import { useLanguage } from "../../../LanguageProvider";

const getTodayDate = () => new Date().toISOString().split("T")[0];

const DateRangeModal = ({ isOpen, onClose, onSave }) => {
  const { getTranslation } = useLanguage();
  const [startDate, setStartDate] = useState(getTodayDate());
  const [endDate, setEndDate] = useState(getTodayDate());

  // Modal açıldığında tarihleri sıfırla
  useEffect(() => {
    if (isOpen) {
      const today = getTodayDate();
      setStartDate(today);
      setEndDate(today);
    }
  }, [isOpen]);

  const handleSave = () => {
    if (new Date(startDate) <= new Date(endDate)) {
      onSave(startDate, endDate); // Tarih aralığını kaydet
      onClose(); // Modal'ı kapat
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} position="middle">
      <div className="card" style={{ marginTop: "40px" }}>
        <div style={{ marginBottom: "15px" }}>
          <label>{getTranslation("startDate")}</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => {
              const value = e.target.value;
              setStartDate(value);
              if (new Date(value) > new Date(endDate)) {
                setEndDate(value); // Başlangıç tarihi bitiş tarihinden büyükse eşitle
              }
            }}
            max={endDate}
            style={{
              width: "100%",
              padding: "5px",
              border: "1px solid #a5a5a5",
              borderRadius: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label>{getTranslation("endDate")}</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => {
              const value = e.target.value;
              setEndDate(value);
              if (new Date(value) < new Date(startDate)) {
                setStartDate(value); // Bitiş tarihi başlangıç tarihinden küçükse eşitle
              }
            }}
            min={startDate}
            style={{
              width: "100%",
              padding: "5px",
              border: "1px solid #a5a5a5",
              borderRadius: "5px",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
            marginTop: "20px",
          }}
        >
          <button onClick={handleSave} className="confirm">
            {getTranslation("save")}
          </button>
          <button
            onClick={onClose}
            style={{
              padding: "8px 16px",
              backgroundColor: "#888",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {getTranslation("close")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DateRangeModal;
