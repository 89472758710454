import React, { useState, useEffect, useContext } from "react";
import "./DateRangeSelector.css";
import { useLanguage } from "../../../LanguageProvider";
import DateRangeModal from "./DateRangeModal";
import { GeneralContext } from "../../../GeneralProvider";

const formatDateForDisplay = (date) => {
  const [year, month, day] = date.split("-");
  return `${day}-${month}-${year}`;
};

const DateRangeSelector = ({ onFetchData }) => {
  const { getTranslation } = useLanguage();
  const { dateRange, setDateRange } = useContext(GeneralContext);
  const [customRange, setCustomRange] = useState(null);
  const [customDates, setCustomDates] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelect = (range) => {
    if (range === "custom") {
      setIsModalOpen(true);
    } else {
      setCustomRange(null);
      setCustomDates(null);
      updateDateRange(range, null);
    }
  };

  const handleSaveCustomRange = (start, end) => {
    const formattedStart = formatDateForDisplay(start);
    const formattedEnd = formatDateForDisplay(end);
    setCustomDates({ start: formattedStart, end: formattedEnd });
    setCustomRange(`${formattedStart} - ${formattedEnd}`);
    updateDateRange("inBetween", { start: formattedStart, end: formattedEnd });
    setIsModalOpen(false);
  };

  const updateDateRange = (type, dates = null) => {
    const mappedType =
      type === "7days"
        ? "last7Days"
        : type === "30days"
        ? "last30Days"
        : type === "365days"
        ? "last365Days"
        : type;

    setDateRange({ type: mappedType, dates });
    localStorage.setItem(
      "dateRange",
      JSON.stringify({ type: mappedType, dates })
    );

    onFetchData({ type: mappedType, dates });
  };

  return (
    <div className="date-range-selector">
      <button
        className={`tab ${dateRange.type === "last7Days" ? "selected" : ""}`}
        onClick={() => handleSelect("7days")}
      >
        {getTranslation("7days")}
      </button>
      <button
        className={`tab ${dateRange.type === "last30Days" ? "selected" : ""}`}
        onClick={() => handleSelect("30days")}
      >
        {getTranslation("30days")}
      </button>
      <button
        className={`tab ${dateRange.type === "last365Days" ? "selected" : ""}`}
        onClick={() => handleSelect("365days")}
      >
        {getTranslation("365days")}
      </button>
      <button
        className={`tab ${dateRange.type === "inBetween" ? "selected" : ""}`}
        onClick={() => handleSelect("custom")}
      >
        {customRange || getTranslation("customRange")}
      </button>

      {isModalOpen && (
        <DateRangeModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveCustomRange}
        />
      )}
    </div>
  );
};

export default DateRangeSelector;
