import React from 'react'

export default function InviteCollaborator() {
    return (
        <div className='main-page'>
            <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                <div>InviteCollaborator</div>
            </div>
        </div>
    )
}
